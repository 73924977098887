import { createContext } from 'react';

export interface IModal {
  isModal: boolean;
  title: string;
  description: string;
  onConfirm: () => void;
}

export interface WarningType extends IModal {
  type?: string;
}

export interface UIState {
  isCreateProject: boolean;
  isFileUpload: boolean;
  isApiKey: boolean;
  isUserInvite: boolean;
  sidebarToggle: boolean;
  deleteConfirmation: IModal;
  warning: WarningType;
}

export const UIInitialState: UIState = {
  isCreateProject: false,
  isFileUpload: false,
  isApiKey: false,
  sidebarToggle: false,
  isUserInvite: false,
  deleteConfirmation: {
    title: '',
    description: '',
    isModal: false,
    onConfirm: () => {},
  },
  warning: {
    title: '',
    description: '',
    isModal: false,
    onConfirm: () => {},
  },
};

export const UIReducer = (state: UIState, action: ActionsType): UIState => {
  switch (action.type) {
    case 'ui/attarius/TOGGLE-MODAL-CREATE-PROJECT': {
      return {
        ...state,
        isCreateProject: action.payload,
      };
    }
    case 'ui/attarius/TOGGLE-SIDEBAR':
      return {
        ...state,
        sidebarToggle: action.payload,
      };
    case 'ui/attarius/TOGGLE-USER-INVITE':
      return {
        ...state,
        isUserInvite: action.payload,
      };
    case 'ui/attarius/OPEN-DELETE-CONFIRMATION':
      return {
        ...state,
        deleteConfirmation: action.payload,
      };
    case 'ui/attarius/CLOSE-DELETE-CONFIRMATION':
      return {
        ...state,
        deleteConfirmation: {
          ...state.deleteConfirmation,
          isModal: action.payload,
          onConfirm: () => {},
        },
      };
    case 'ui/attarius/CLOSE-WARNING-MODAL':
      return {
        ...state,
        warning: {
          ...state.warning,
          isModal: action.payload,
          onConfirm: () => {},
        },
      };
    case 'ui/attarius/OPEN-WARNING-MODAL':
      return {
        ...state,
        warning: action.payload,
      };
    case 'ui/attarius/TOGGLE-API-KEY':
      return {
        ...state,
        isApiKey: !state.isApiKey,
      };
    case 'ui/attarius/TOGGLE-FILE-UPLOAD':
      return {
        ...state,
        isFileUpload: !state.isFileUpload,
      };
    default:
      return state;
  }
};

export const toggleProject = (isCreateProject: boolean) =>
  ({
    type: 'ui/attarius/TOGGLE-MODAL-CREATE-PROJECT',
    payload: isCreateProject,
  } as const);

export const setToggleSidebar = (sidebarToggle: boolean) =>
  ({
    type: 'ui/attarius/TOGGLE-SIDEBAR',
    payload: sidebarToggle,
  } as const);

export const setToggleUserIvire = (isUserInvite: boolean) =>
  ({
    type: 'ui/attarius/TOGGLE-USER-INVITE',
    payload: isUserInvite,
  } as const);

export const openDelete = (deleteConfirmation: IModal) =>
  ({
    type: 'ui/attarius/OPEN-DELETE-CONFIRMATION',
    payload: deleteConfirmation,
  } as const);

export const closeDelete = (sidebarToggle: boolean) =>
  ({
    type: 'ui/attarius/CLOSE-DELETE-CONFIRMATION',
    payload: sidebarToggle,
  } as const);

export const openWarning = (warning: WarningType) =>
  ({
    type: 'ui/attarius/OPEN-WARNING-MODAL',
    payload: warning,
  } as const);

export const closeWarning = (isWarning: boolean) =>
  ({
    type: 'ui/attarius/CLOSE-WARNING-MODAL',
    payload: isWarning,
  } as const);

export const toggleApiKey = () =>
  ({ type: 'ui/attarius/TOGGLE-API-KEY' } as const);

export const toggleFileUpload = () =>
  ({ type: 'ui/attarius/TOGGLE-FILE-UPLOAD' } as const);

type ActionsType =
  | ReturnType<typeof toggleProject>
  | ReturnType<typeof setToggleSidebar>
  | ReturnType<typeof openDelete>
  | ReturnType<typeof closeDelete>
  | ReturnType<typeof setToggleUserIvire>
  | ReturnType<typeof openWarning>
  | ReturnType<typeof closeWarning>
  | ReturnType<typeof toggleApiKey>
  | ReturnType<typeof toggleFileUpload>;

interface UIContextValue extends UIState {
  setModalToggle: () => void;
  toggleSidebar: () => void;
  toggleUserInvite: () => void;
  openDeleteModal: (
    title: string,
    description: string,
    onConfirm: () => void,
  ) => void;
  closeDeleteModal: () => void;
  openWarningModal: (
    title: string,
    description: string,
    onConfirm: () => void,
    type?: string,
  ) => void;
  closeWarningModal: () => void;
  toggleApiKeyModal: () => void;
  toggleFileUploadModal: () => void;
}

export const UIContext = createContext<UIContextValue>({
  ...UIInitialState,
  setModalToggle: () => {},
  toggleSidebar: () => {},
  toggleUserInvite: () => {},
  openDeleteModal: () => {},
  closeDeleteModal: () => {},
  openWarningModal: () => {},
  closeWarningModal: () => {},
  toggleApiKeyModal: () => {},
  toggleFileUploadModal: () => {},
});
