import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children || <Outlet />}</>;
};

BaseLayout.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default BaseLayout;
