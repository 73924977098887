import { Box, Drawer, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import useUI from '../../../../hooks/use-ui';
import SidebarMenu from './SidebarMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        margin-top: ${theme.header.height};
        z-index: 5;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
        }
`,
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useUI();
  const closeSidebar = () => toggleSidebar();

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <SidebarMenu />
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper sx={{ marginTop: '0 !important' }}>
            <SidebarMenu />
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
