/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Badge, ListItem } from '@mui/material';

import useUI from '../../../../../hooks/use-ui';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  active?: boolean;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  active,
  name,
}) => {
  const { toggleSidebar } = useUI();
  const navigate = useNavigate();

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name}>
        <Button
          // className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon && <Icon />}
        >
          {name}
        </Button>
      </ListItem>
    );
  }

  const activeStyle = {
    backgroundColor: '#f2f5f9 !important',
    color: '#2139eb !important',
  };

  const onClickButton = () => {
    toggleSidebar();
    navigate(`${link}`);
  };

  return (
    <ListItem component="div" key={name}>
      <Button
        sx={active ? activeStyle : {}}
        onClick={onClickButton}
        startIcon={Icon && <Icon />}
      >
        {name}
        {badge && <Badge badgeContent={badge} />}
      </Button>
    </ListItem>
  );
};

export default SidebarMenuItem;
