import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  addFootballers,
  changeLoading,
  deleteFootballPlayer,
  FootballerContext,
  FootballerInitialState,
  getFootballersList,
  getFootballPlayer,
  IFootballer,
  footballerReducer,
  resetFootballer,
  updateFootballPlayer,
} from '../reducers/footballerReducer';
import { footballerAPI } from '../api/api';

type ProviderProps = {
  children: ReactNode;
};

const FootballerProvider = (props: ProviderProps): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(
    footballerReducer,
    FootballerInitialState,
  );
  const navigate = useNavigate();

  const getFootballers = useCallback(async (isActive: boolean) => {
    dispatch(changeLoading(true));
    try {
      const result = await footballerAPI.getFootballers(isActive);
      dispatch(getFootballersList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getFootballer = useCallback(async (footballerId: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await footballerAPI.getFootballer(footballerId);
      dispatch(getFootballPlayer(result.data.data));
      // console.log(result.data.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const createFootballer = useCallback(
    async (footballer: IFootballer): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await footballerAPI.createFootballer(footballer);
        const { id } = result.data.data;
        dispatch(addFootballers(result.data.data));
        toast.success('Footballer added successfully!');
        navigate(`/footballers/${id}`);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(e?.message || 'Something went wrong!');
        }
        // toast.error(e.response.data.message || 'Something went wrong!');
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [navigate],
  );

  const updateFootballer = useCallback(
    async (footballer: IFootballer, id: string): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await footballerAPI.updateFootballer(footballer, id);
        dispatch(updateFootballPlayer(result.data.data));
        toast.success('Footballer updated successfully!');
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(e?.message || 'Something went wrong!');
        }
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [],
  );

  const deleteFootballer = useCallback(async (footballerId: string) => {
    dispatch(changeLoading(true));
    try {
      await footballerAPI.deleteFootballer(footballerId);
      dispatch(deleteFootballPlayer(footballerId));
      toast.success('Footballer deleted successfully!');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const clearFootballer = useCallback(() => {
    dispatch(resetFootballer());
  }, []);

  const ProviderValue = useMemo(
    () => ({
      ...state,
      getFootballers,
      createFootballer,
      getFootballer,
      clearFootballer,
      updateFootballer,
      deleteFootballer,
    }),
    [
      state,
      getFootballers,
      createFootballer,
      getFootballer,
      clearFootballer,
      updateFootballer,
      deleteFootballer,
    ],
  );

  return (
    <FootballerContext.Provider value={ProviderValue}>
      {children}
    </FootballerContext.Provider>
  );
};

export default FootballerProvider;
