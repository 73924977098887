/* eslint-disable no-underscore-dangle */
import { createContext } from 'react';
import { UseFormSetError } from 'react-hook-form';

export type TypeField = 'string' | 'select' | 'checked';
export type TypeSeasonPoll = 'base' | 'lineUp' | 'community';

export type OptionType = {
  title: string;
  typeField: TypeField;
  description: string;
  selectValues?: string[];
  _id?: string;
};

export interface ISeasonPollItem {
  SeasonPoll: ISeasonPoll;
  rightAnswers: string[];
  isRightAnswersAdded: boolean;
}

export interface ISeasonPoll {
  title: string;
  isEnabled: boolean;
  description: string;
  pollsFinishDate: Date;
  amountPoints: number;
  options: OptionSeasonType[];
  typePoll: TypeSeasonPoll;
  id?: string;
  _id?: string;
}

export type OptionSeasonType = {
  footballer: string;
  typeField: TypeField;
  votes?: number;
  _id?: string;
};

export type SeasonPollStateType = {
  SeasonPolls: ISeasonPoll[];
  SeasonPoll: ISeasonPoll | null;
  isLoading: boolean;
};

export const SeasonPollInitialState: SeasonPollStateType = {
  SeasonPolls: [],
  SeasonPoll: null,
  isLoading: false,
};

export const SeasonPollReducer = (
  state: SeasonPollStateType,
  action: ActionsType,
): SeasonPollStateType => {
  switch (action.type) {
    case 'SeasonPoll/reds-club/CHANGE-IS-LOADING':
      return {
        ...state,
        ...action.payload,
      };
    case 'SeasonPoll/reds-club/GET-SeasonPollS':
    case 'SeasonPoll/reds-club/GET-SeasonPoll':
    case 'SeasonPoll/reds-club/RESET-SeasonPoll':
    case 'SeasonPoll/reds-club/UPDATE-SeasonPoll':
      return {
        ...state,
        ...action.payload,
      };
    case 'SeasonPoll/reds-club/DELETE-SeasonPoll':
      return {
        ...state,
        SeasonPolls: [
          ...state.SeasonPolls.filter((el) => el.id !== action.payload),
        ],
      };
    case 'SeasonPoll/reds-club/ADD-SeasonPoll':
      return {
        ...state,
        SeasonPolls: [...state.SeasonPolls, action.payload],
      };
    case 'SeasonPoll/reds-club/ADD-OPTION': {
      const options = state.SeasonPoll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SeasonPoll: {
          ...state.SeasonPoll,
          options: [
            ...options,
            { footballer: '', votes: 0, typeField: 'string' },
          ],
        },
      };
    }
    case 'SeasonPoll/reds-club/DELETE-OPTION': {
      const { optionId, index } = action.payload;
      const options = state.SeasonPoll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SeasonPoll: {
          ...state.SeasonPoll,
          options: options.filter((el, i) => {
            if (optionId) {
              return el._id !== optionId;
            }
            return i !== index;
          }),
        },
      };
    }
    case 'SeasonPoll/reds-club/CHANGE-OPTION-FIELD': {
      const { name, value, index } = action.payload;
      const nameField = name.substring(10, name.length);
      const options = state.SeasonPoll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SeasonPoll: {
          ...state.SeasonPoll,
          options: options.map((el, i) => {
            if (i === index) {
              return { ...el, [`${nameField}`]: value };
            }
            return el;
          }),
        },
      };
    }
    case 'SeasonPoll/reds-club/RESET-OPTION-SELECT-VALUES': {
      const options = state.SeasonPoll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SeasonPoll: {
          ...state.SeasonPoll,
          options: options.map((el, i) => {
            if (i === action.payload) {
              return { ...el, selectValues: [] };
            }
            return el;
          }),
        },
      };
    }
    case 'SeasonPoll/reds-club/CHANGE-SeasonPoll-TYPE': {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SeasonPoll: {
          ...state.SeasonPoll,
          typePoll: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const resetSeasonPoll = () =>
  ({
    type: 'SeasonPoll/reds-club/RESET-SeasonPoll',
    payload: { SeasonPoll: null },
  } as const);

export const getSeasonPollsList = (SeasonPolls: ISeasonPoll[]) =>
  ({
    type: 'SeasonPoll/reds-club/GET-SeasonPollS',
    payload: { SeasonPolls },
  } as const);

export const getSeasonPollItem = (SeasonPoll: ISeasonPoll) =>
  ({
    type: 'SeasonPoll/reds-club/GET-SeasonPoll',
    payload: { SeasonPoll },
  } as const);

export const changeSeasonPoll = (SeasonPoll: ISeasonPoll) =>
  ({
    type: 'SeasonPoll/reds-club/UPDATE-SeasonPoll',
    payload: { SeasonPoll },
  } as const);

export const addSeasonPoll = (SeasonPoll: ISeasonPoll) =>
  ({
    type: 'SeasonPoll/reds-club/ADD-SeasonPoll',
    payload: SeasonPoll,
  } as const);

export const removeSeasonPoll = (SeasonPollId: string) =>
  ({
    type: 'SeasonPoll/reds-club/DELETE-SeasonPoll',
    payload: SeasonPollId,
  } as const);

export const changeLoading = (isLoading: boolean) =>
  ({
    type: 'SeasonPoll/reds-club/CHANGE-IS-LOADING',
    payload: { isLoading },
  } as const);

export const addNewOption = () =>
  ({ type: 'SeasonPoll/reds-club/ADD-OPTION' } as const);

export const deleteOption = (optionId: string | undefined, index: number) =>
  ({
    type: 'SeasonPoll/reds-club/DELETE-OPTION',
    payload: { optionId, index },
  } as const);

export const changeOptionField = (index: number, name: string, value: string) =>
  ({
    type: 'SeasonPoll/reds-club/CHANGE-OPTION-FIELD',
    payload: { name, value, index },
  } as const);

export const changeTypeSeasonPoll = (value: TypeSeasonPoll) =>
  ({
    type: 'SeasonPoll/reds-club/CHANGE-SeasonPoll-TYPE',
    payload: value,
  } as const);

export const addSelectValue = (index: number, value: string) =>
  ({
    type: 'SeasonPoll/reds-club/ADD-OPTION-SELECT-VALUE',
    payload: { index, value },
  } as const);

export const deleteSelectValue = (optionId: number, index: number) =>
  ({
    type: 'SeasonPoll/reds-club/DELETE-OPTION-SELECT-VALUE',
    payload: { optionId, index },
  } as const);

export const resetSelectValue = (index: number) =>
  ({
    type: 'SeasonPoll/reds-club/RESET-OPTION-SELECT-VALUES',
    payload: index,
  } as const);

type ActionsType =
  | ReturnType<typeof getSeasonPollsList>
  | ReturnType<typeof removeSeasonPoll>
  | ReturnType<typeof addSeasonPoll>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof resetSeasonPoll>
  | ReturnType<typeof changeSeasonPoll>
  | ReturnType<typeof getSeasonPollItem>
  | ReturnType<typeof addNewOption>
  | ReturnType<typeof deleteOption>
  | ReturnType<typeof changeOptionField>
  | ReturnType<typeof addSelectValue>
  | ReturnType<typeof resetSelectValue>
  | ReturnType<typeof changeTypeSeasonPoll>
  | ReturnType<typeof deleteSelectValue>;

interface SeasonPollContextValue extends SeasonPollStateType {
  getSeasonPolls: () => Promise<void>;
  createSeasonPoll: (SeasonPoll: ISeasonPoll) => Promise<void>;
  getSeasonPoll: (SeasonPollId: string) => Promise<void>;
  deleteSeasonPoll: (SeasonPollId: string) => Promise<void>;
  updateSeasonPoll: (
    SeasonPoll: ISeasonPoll,
    id: string,
    setError: UseFormSetError<ISeasonPoll>,
  ) => Promise<void>;
  clearSeasonPoll: () => void;
  addOption: () => void;
  removeOption: (optionId: string | undefined, index: number) => void;
  updateOptionField: (index: number, name: string, value: string) => void;
  createSelectValue: (optionId: number, value: string) => void;
  clearSelectValues: (optionId: number) => void;
  removeSelectValue: (optionId: number, index: number) => void;
  changeType: (value: TypeSeasonPoll) => void;
}

export const SeasonPollContext = createContext<SeasonPollContextValue>({
  ...SeasonPollInitialState,
  getSeasonPolls: () => Promise.resolve(),
  createSeasonPoll: () => Promise.resolve(),
  getSeasonPoll: () => Promise.resolve(),
  deleteSeasonPoll: () => Promise.resolve(),
  updateSeasonPoll: () => Promise.resolve(),
  clearSeasonPoll: () => {},
  addOption: () => {},
  removeOption: () => {},
  updateOptionField: () => {},
  createSelectValue: () => {},
  clearSelectValues: () => {},
  removeSelectValue: () => {},
  changeType: () => {},
});
