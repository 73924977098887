import { createContext } from 'react';

export type User = {
  email: string;
} | null;

export type LoginType = {
  email: string;
  password: string;
};

export type AuthStateType = {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: LoginType;
  user: User;
};

export const AuthInitialState: AuthStateType = {
  isAuthenticated: false,
  isInitialized: false,
  login: {
    email: '',
    password: '',
  },
  user: null,
  isLoading: false,
};

export const authReducer = (
  state: AuthStateType,
  action: ActionsType,
): AuthStateType => {
  switch (action.type) {
    case 'auth/attarius/CHANGE-AUTH-DATA': {
      const { name, value } = action.payload;
      return {
        ...state,
        login: { ...state.login, [name]: value },
      };
    }
    case 'auth/attarius/LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isInitialized: true,
      };
    case 'auth/attarius/LOGIN':
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isInitialized: false,
      };
    case 'auth/attarius/SET-IS-LOADING':
    case 'auth/attarius/SET-USER':
      return { ...state, ...action.payload };
    case 'auth/attarius/CLEAR-LOGIN':
      return {
        ...state,
        login: { email: '', password: '' },
      };
    case 'auth/attarius/INITIALIZE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        user,
      };
    }
    case 'auth/attarius/UPDATE-USER':
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export const changeAuthData = (name: string, value: string) =>
  ({
    type: 'auth/attarius/CHANGE-AUTH-DATA',
    payload: { name, value },
  } as const);

export const loading = (isLoading: boolean) =>
  ({
    type: 'auth/attarius/SET-IS-LOADING',
    payload: { isLoading },
  } as const);

export const setLogin = (user: User) =>
  ({ type: 'auth/attarius/LOGIN', payload: { user } } as const);

export const clearLogin = () =>
  ({ type: 'auth/attarius/CLEAR-LOGIN' } as const);

export const setUser = (user: User | null) =>
  ({ type: 'auth/attarius/SET-USER', payload: { user } } as const);

export const logout = () => ({ type: 'auth/attarius/LOGOUT' } as const);

export const initialize = (isAuthenticated: boolean, user: User) =>
  ({
    type: 'auth/attarius/INITIALIZE',
    payload: { isAuthenticated, user },
  } as const);

export const updateUserProfile = (user: User) =>
  ({ type: 'auth/attarius/UPDATE-USER', payload: user } as const);

type ActionsType =
  | ReturnType<typeof changeAuthData>
  | ReturnType<typeof loading>
  | ReturnType<typeof setLogin>
  | ReturnType<typeof logout>
  | ReturnType<typeof initialize>
  | ReturnType<typeof setUser>
  | ReturnType<typeof clearLogin>
  | ReturnType<typeof updateUserProfile>;

interface AuthContextValue extends AuthStateType {
  logoutAccount: () => void;
  changeAuth: (value: string, name: string) => void;
  loginAdmin: (data: LoginType) => void;
}
export const AuthContext = createContext<AuthContextValue>({
  ...AuthInitialState,
  logoutAccount: () => {},
  changeAuth: () => {},
  loginAdmin: () => {},
});
