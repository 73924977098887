import { createContext } from 'react';

export interface IFootballer {
  name: string;
  isActive: boolean;
  id?: string;
}

export type FootballerStateType = {
  footballers: IFootballer[];
  footballer: IFootballer | null;
  isLoading: boolean;
};

export const FootballerInitialState: FootballerStateType = {
  footballers: [],
  footballer: null,
  isLoading: false,
};

export const footballerReducer = (
  state: FootballerStateType,
  action: ActionsType,
): FootballerStateType => {
  switch (action.type) {
    case 'footballer/reds-club/CHANGE-IS-LOADING':
      return {
        ...state,
        ...action.payload,
      };
    case 'footballer/reds-club/GET-FOOTBALLERS':
    case 'footballer/reds-club/GET-FOOTBALLER':
    case 'footballer/reds-club/RESET-FOOTBALLER':
    case 'footballer/reds-club/UPDATE-FOOTBALLER':
      return {
        ...state,
        ...action.payload,
      };
    case 'footballer/reds-club/DELETE-FOOTBALLER':
      return {
        ...state,
        footballers: [
          ...state.footballers.filter((el) => el.id !== action.payload),
        ],
      };
    case 'footballer/reds-club/ADD-FOOTBALLER':
      return {
        ...state,
        footballers: [...state.footballers, action.payload],
      };
    default:
      return state;
  }
};

export const resetFootballer = () =>
  ({
    type: 'footballer/reds-club/RESET-FOOTBALLER',
    payload: { footballer: null },
  } as const);

export const getFootballersList = (footballers: IFootballer[]) =>
  ({
    type: 'footballer/reds-club/GET-FOOTBALLERS',
    payload: { footballers },
  } as const);

export const getFootballPlayer = (footballer: IFootballer) =>
  ({
    type: 'footballer/reds-club/GET-FOOTBALLER',
    payload: { footballer },
  } as const);

export const updateFootballPlayer = (footballer: IFootballer) =>
  ({
    type: 'footballer/reds-club/UPDATE-FOOTBALLER',
    payload: { footballer },
  } as const);

export const addFootballers = (footballer: IFootballer) =>
  ({
    type: 'footballer/reds-club/ADD-FOOTBALLER',
    payload: footballer,
  } as const);

export const deleteFootballPlayer = (footballerId: string) =>
  ({
    type: 'footballer/reds-club/DELETE-FOOTBALLER',
    payload: footballerId,
  } as const);

export const changeLoading = (isLoading: boolean) =>
  ({
    type: 'footballer/reds-club/CHANGE-IS-LOADING',
    payload: { isLoading },
  } as const);

type ActionsType =
  | ReturnType<typeof getFootballersList>
  | ReturnType<typeof deleteFootballPlayer>
  | ReturnType<typeof addFootballers>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof resetFootballer>
  | ReturnType<typeof updateFootballPlayer>
  | ReturnType<typeof getFootballPlayer>;

interface FootballerContextValue extends FootballerStateType {
  getFootballers: (isActive: boolean) => Promise<void>;
  createFootballer: (footballer: IFootballer) => Promise<void>;
  getFootballer: (footballerId: string) => Promise<void>;
  deleteFootballer: (footballerId: string) => Promise<void>;
  updateFootballer: (footballer: IFootballer, id: string) => Promise<void>;
  clearFootballer: () => void;
}

export const FootballerContext = createContext<FootballerContextValue>({
  ...FootballerInitialState,
  getFootballers: () => Promise.resolve(),
  createFootballer: () => Promise.resolve(),
  getFootballer: () => Promise.resolve(),
  deleteFootballer: () => Promise.resolve(),
  updateFootballer: () => Promise.resolve(),
  clearFootballer: () => {},
});
