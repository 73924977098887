import { useMemo, useCallback, useReducer, ReactNode } from 'react';
import {
  UIInitialState,
  UIReducer,
  setToggleSidebar,
  toggleProject,
  UIContext,
  openDelete,
  closeDelete,
  setToggleUserIvire,
  openWarning,
  closeWarning,
  toggleApiKey,
  toggleFileUpload,
} from '../reducers/UIReducer';

type PropsType = {
  children: ReactNode;
};

const UIProvider = (props: PropsType) => {
  const { children } = props;
  const [state, dispatch] = useReducer(UIReducer, UIInitialState);

  const toggleSidebar = useCallback(() => {
    dispatch(setToggleSidebar(!state.sidebarToggle));
  }, [state.sidebarToggle]);

  const toggleUserInvite = useCallback(() => {
    dispatch(setToggleUserIvire(!state.isUserInvite));
  }, [state.isUserInvite]);

  const setModalToggle = useCallback(() => {
    dispatch(toggleProject(!state.isCreateProject));
  }, [state.isCreateProject]);

  const openDeleteModal = useCallback(
    (title: string, description: string, onConfirm: () => void) => {
      dispatch(openDelete({ title, description, isModal: true, onConfirm }));
    },
    [],
  );

  const closeDeleteModal = useCallback(() => {
    dispatch(closeDelete(false));
  }, []);

  const openWarningModal = useCallback(
    (
      title: string,
      description: string,
      onConfirm: () => void,
      type?: string,
    ) => {
      dispatch(
        openWarning({ title, description, isModal: true, onConfirm, type }),
      );
    },
    [],
  );

  const closeWarningModal = useCallback(() => {
    dispatch(closeWarning(false));
  }, []);

  const toggleApiKeyModal = useCallback(() => {
    dispatch(toggleApiKey());
  }, []);

  const toggleFileUploadModal = useCallback(() => {
    dispatch(toggleFileUpload());
  }, []);

  const contextValue = useMemo(
    () => ({
      ...state,
      toggleSidebar,
      setModalToggle,
      openDeleteModal,
      closeDeleteModal,
      toggleUserInvite,
      openWarningModal,
      closeWarningModal,
      toggleApiKeyModal,
      toggleFileUploadModal,
    }),
    [
      state,
      toggleSidebar,
      setModalToggle,
      openDeleteModal,
      closeDeleteModal,
      toggleUserInvite,
      openWarningModal,
      closeWarningModal,
      toggleApiKeyModal,
      toggleFileUploadModal,
    ],
  );

  return (
    <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>
  );
};

export default UIProvider;
