import './App.css';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import routes from './router';
import FootballerProvider from './contexts/FootballerContext';
import ThemeProvider from './theme/ThemeProvider';
import PollProvider from './contexts/PollContext';
import MatchProvider from './contexts/MatchContext';
import SeasonProvider from './contexts/SeasonContext';
import SeasonPollProvider from './contexts/SeasonPollContext';

function App() {
  const content = useRoutes(routes);
  return (
    <SeasonPollProvider>
      <SeasonProvider>
        <FootballerProvider>
          <PollProvider>
            <MatchProvider>
              <ThemeProvider>
                <Toaster />
                {content}
              </ThemeProvider>
            </MatchProvider>
          </PollProvider>
        </FootballerProvider>
      </SeasonProvider>
    </SeasonPollProvider>
  );
}

export default App;
