import { FC, ReactNode, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useLocation, Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainContent = styled(Box)(
  ({ theme }) => `
    margin-top: ${theme.header.height};
    flex: 1 1 auto;
    overflow: auto;
`,
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const { pathname } = useLocation();
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    setIsHidden(true);
  }, [pathname]);

  return (
    <>
      {isHidden && <Sidebar />}
      <Header isHidden={isHidden} />
      <Box
        sx={{
          paddingLeft: !isHidden ? '0 !important' : '',
          flex: '1 1 auto',
          display: 'flex',
          height: '100%',
          '@media (min-width: 1280px)': {
            paddingLeft: isHidden ? `280px` : '0',
          },
          // '@media (min-width: 1200px)': {
          //   paddingLeft: isHidden ? `280px` : '0',
          //   paddingTop: isHidden ? `88px` : '0',
          // },
          // '@media (max-width: 1200px)': {
          //   paddingTop: '88px',
          // },
        }}
      >
        <MainContent>
          <Outlet />
        </MainContent>
      </Box>
    </>
  );
};

SidebarLayout.defaultProps = {
  children: null,
};

export default SidebarLayout;
