import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { LoginType, User } from '../reducers/authReducer';
import { IMatch } from '../reducers/matchReducer';
import { IPoll } from '../reducers/pollReducer';
import { ISeasonPoll } from '../reducers/seasonPollReducer';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'https://api.redsclub.io/',
});

instance.interceptors.request.use((config: AxiosRequestConfig) => {
  const { headers } = config;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  headers.Authorization = `${localStorage.getItem('redsclubToken')}`;

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // localStorage.removeItem('redsclubToken');
      // window.location.replace('/authentication');
    }
    return Promise.reject(error);
  },
);

export const authAPI = {
  async login(data: LoginType): Promise<LoginResponseType> {
    return instance
      .post('/auth/login', data)
      .then((res: { data: LoginResponseType }): LoginResponseType => res.data);
  },
};

export const footballerAPI = {
  async getFootballers(isActive: boolean): Promise<AxiosResponse> {
    return instance.get(`/footballer?isActive=${isActive}`);
  },
  async getFootballer(id: string): Promise<AxiosResponse> {
    return instance.get(`/footballer/${id}`);
  },
  async createFootballer(footballer: FootballerType): Promise<AxiosResponse> {
    return instance.post('/footballer', footballer);
  },
  async updateFootballer(
    footballer: FootballerType,
    id: string,
  ): Promise<AxiosResponse> {
    return instance.patch(`/footballer/${id}`, footballer);
  },
  async deleteFootballer(id: string): Promise<AxiosResponse> {
    return instance.delete(`/footballer/${id}`);
  },
};

export const seasonAPI = {
  async getSeasons(): Promise<AxiosResponse> {
    return instance.get('/season');
  },
  async getVotes(): Promise<AxiosResponse> {
    return instance.get('/season/allVotes');
  },
  async getSeason(id: string): Promise<AxiosResponse> {
    return instance.get(`/season/${id}`);
  },
  async createSeason(season: SeasonType): Promise<AxiosResponse> {
    return instance.post('/season', season);
  },
  async updateSeason(season: SeasonType, id: string): Promise<AxiosResponse> {
    return instance.patch(`/season/${id}`, season);
  },
  async deleteSeason(id: string): Promise<AxiosResponse> {
    return instance.delete(`/season/${id}`);
  },
};

export const pollAPI = {
  async getPolls(): Promise<AxiosResponse> {
    return instance.get('/poll');
  },
  async getPoll(id: string): Promise<AxiosResponse> {
    return instance.get(`/poll/${id}`);
  },
  async filterPolls(typeName: string): Promise<AxiosResponse> {
    return instance.get(`/poll/type/${typeName}`);
  },
  async createPoll(poll: IPoll): Promise<AxiosResponse> {
    return instance.post('/poll', poll);
  },
  async updatePoll(poll: IPoll, id: string): Promise<AxiosResponse> {
    return instance.patch(`/poll/${id}`, poll);
  },
  async deletePoll(id: string): Promise<AxiosResponse> {
    return instance.delete(`/poll/${id}`);
  },
  async addRightAnswers(
    id: string,
    rightAnswers: [{ pollId: string; answers: string[] }],
  ): Promise<AxiosResponse> {
    return instance.post(`/match/${id}/rightAnswers`, { rightAnswers });
  },
};

export const seasonPollAPI = {
  async getSeasonPolls(): Promise<AxiosResponse> {
    return instance.get('/poll/globalPolls');
  },
  async getSeasonPoll(id: string): Promise<AxiosResponse> {
    return instance.get(`/poll/globalPoll/${id}`);
  },
  async createSeasonPoll(poll: ISeasonPoll): Promise<AxiosResponse> {
    return instance.post('/poll/createGlobalPoll', poll);
  },
  async updateSeasonPoll(
    poll: ISeasonPoll,
    id: string,
  ): Promise<AxiosResponse> {
    return instance.patch(`/poll/globalPoll/${id}`, poll);
  },
  async deleteSeasonPoll(id: string): Promise<AxiosResponse> {
    return instance.delete(`/poll/globalPoll/${id}`);
  },
};

export const matchAPI = {
  async getMatches(): Promise<AxiosResponse> {
    return instance.get('/match');
  },
  async getMatch(id: string): Promise<AxiosResponse> {
    return instance.get(`/match/${id}`);
  },
  async getOpponents(): Promise<AxiosResponse> {
    return instance.get('/match/logo');
  },
  async createMatch(match: IMatch): Promise<AxiosResponse> {
    return instance.post('/match', match);
  },
  async updateMatch(match: IMatch, id: string): Promise<AxiosResponse> {
    return instance.patch(`/match/${id}`, match);
  },
  async deleteMatch(id: string): Promise<AxiosResponse> {
    return instance.delete(`/match/${id}`);
  },
  async deletePoll(matchId: string, pollId: string): Promise<AxiosResponse> {
    return instance.delete(`/match/${matchId}/polls/${pollId}`);
  },
  async addPoll(matchId: string, pollId: string): Promise<AxiosResponse> {
    return instance.post(`/match/${matchId}/polls/${pollId}`);
  },
};

type LoginResponseType = {
  token: { token: string };
  user: User;
};

export type FootballerType = {
  name: string;
  isActive: boolean;
};

export type SeasonType = {
  title: string;
  isActual: boolean;
};
