/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router-dom';

import SidebarLayout from './content/dashboard/SidebarLayout';
import BaseLayout from './content/dashboard/BaseLayout';

import SuspenseLoader from './components/SuspenseLoader/SuspenseLoader';
// import Overview from './content/overview';

const Loader =
  (Component: React.ComponentType) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <T,>(props: T) =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        {/* 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
        <Component {...props} />
      </Suspense>
    );

// Pages

const Login = Loader(lazy(() => import('./content/authentication/Login')));

const Footballers = Loader(
  lazy(() => import('./content/pages/Footballer/FootballersList')),
);

const Footballer = Loader(
  lazy(() => import('./content/pages/Footballer/Footballer')),
);

const Polls = Loader(lazy(() => import('./content/pages/Poll/PollsList')));

const Poll = Loader(lazy(() => import('./content/pages/Poll/Poll')));

const Matches = Loader(lazy(() => import('./content/pages/Match/MatchesList')));

const Seasons = Loader(
  lazy(() => import('./content/pages/Season/SeasonsList')),
);

const Votes = Loader(lazy(() => import('./content/pages/Votes/VotesList')));

const Season = Loader(lazy(() => import('./content/pages/Season/Season')));

const SeasonPolls = Loader(
  lazy(() => import('./content/pages/SeasonPoll/SeasonPollsList')),
);

const SeasonPoll = Loader(
  lazy(() => import('./content/pages/SeasonPoll/SeasonPoll')),
);

const Match = Loader(lazy(() => import('./content/pages/Match/Match')));

// Dashboards

const Home = Loader(lazy(() => import('./components/Home/Home')));

// Status

// const Status404 = Loader(
//   lazy(() => import('src/content/pages/Status/Status404')),
// );
// const Status500 = Loader(
//   lazy(() => import('src/content/pages/Status/Status500')),
// );
// const StatusMaintenance = Loader(
//   lazy(() => import('src/content/pages/Status/Maintenance')),
// );

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <SidebarLayout />,
        children: [{ path: '/', element: <Home /> }],
      },
      {
        path: '/authentication',
        element: <Login />,
      },
      // {
      //   path: 'status',
      //   children: [
      //     {
      //       path: '',
      //       element: <Navigate to="404" replace />,
      //     },
      //     {
      //       path: '404',
      //       element: <Status404 />,
      //     },
      //     {
      //       path: '500',
      //       element: <Status500 />,
      //     },
      //     {
      //       path: 'maintenance',
      //       element: <StatusMaintenance />,
      //     },
      //   ],
      // },
      // {
      //   path: '*',
      //   element: <Status404 />,
      // },
    ],
  },
  {
    path: 'footballers',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Footballers />,
      },
      {
        path: 'create',
        element: <Footballer />,
      },
      {
        path: ':footballerId',
        element: <Footballer />,
      },
    ],
  },
  {
    path: 'seasons',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Seasons />,
      },
      {
        path: 'create',
        element: <Season />,
      },
      {
        path: ':seasonId',
        element: <Season />,
      },
    ],
  },
  {
    path: 'polls',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Polls />,
      },
      {
        path: 'create',
        element: <Poll />,
      },
      {
        path: ':pollId',
        element: <Poll />,
      },
    ],
  },
  {
    path: 'seasonPolls',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <SeasonPolls />,
      },
      {
        path: 'create',
        element: <SeasonPoll />,
      },
      {
        path: ':seasonPollId',
        element: <SeasonPoll />,
      },
    ],
  },
  {
    path: 'votes',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Votes />,
      },
    ],
  },
  {
    path: 'matches',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Matches />,
      },
      {
        path: 'create',
        element: <Match />,
      },
      {
        path: ':matchId',
        element: <Match />,
      },
    ],
  },
];

export default routes;
