/* eslint-disable @typescript-eslint/no-explicit-any */
import { List } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// import StickyNote2TwoToneIcon from '@mui/icons-material/StickyNote2TwoTone';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import PollIcon from '@mui/icons-material/Poll';
import SportsIcon from '@mui/icons-material/Sports';
import SeasonIcon from '@mui/icons-material/AccessTimeFilled';
import SeasonPoll from '@mui/icons-material/HowToVote';
import BallotIcon from '@mui/icons-material/Ballot';
// import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
// import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
// import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
// import { OverridableComponent } from '@mui/material/OverridableComponent';
import SidebarMenuItem from './item';

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
    &.MuiList-root {
      padding: 0;

      .MuiList-root .MuiList-root .MuiListItem-root .MuiButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {

        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(13)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`,
);

const renderSidebarMenuItems = ({
  item,
  path,
}: {
  item: MenuItem;
  path: string;
}): JSX.Element => {
  const childpath = path
    .split('/')
    .includes(item.name.toLowerCase().slice(0, -1));
  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: false,
        },
        path,
      ) || childpath
    : false;
  return (
    <SubMenuWrapper key={item.name}>
      <SidebarMenuItem
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    </SubMenuWrapper>
  );
};

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

function SidebarMenu() {
  const location = useLocation();

  const menuItems: MenuItem[] = [
    {
      name: 'Footballers',
      link: `/footballers`,
      icon: SportsSoccerIcon,
    },
    {
      name: 'Polls',
      link: `/polls`,
      icon: PollIcon,
    },
    {
      name: 'Season polls',
      link: `/seasonPolls`,
      icon: SeasonPoll,
    },
    {
      name: 'Matches',
      link: `/matches`,
      icon: SportsIcon,
    },
    {
      name: 'Seasons',
      link: `/seasons`,
      icon: SeasonIcon,
    },
    {
      name: 'Votes',
      link: `/votes`,
      icon: BallotIcon,
    },
  ];

  return (
    <>
      {menuItems.map((item) =>
        renderSidebarMenuItems({
          item,
          path: location.pathname,
        }),
      )}
    </>
  );
}

export default SidebarMenu;
