import { FC } from 'react';
import { Box, Button, Hidden, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import { useLocation } from 'react-router-dom';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import HeaderButtons from './Buttons';
import useUI from '../../../../hooks/use-ui';
import useAuth from '../../../../hooks/use-auth';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 10;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {

        }
`,
);

type PropsType = {
  isHidden: boolean;
};

const Header: FC<PropsType> = ({ isHidden }) => {
  const { sidebarToggle, toggleSidebar } = useUI();
  const { logoutAccount } = useAuth();
  const { pathname } = useLocation();
  const isHiddenButtons = !!pathname.split('/')[1];

  return (
    <HeaderWrapper
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Box display="flex" alignItems="center">
        {isHiddenButtons && <HeaderButtons />}
        <Box sx={{ m: 1, alignSelf: 'flex-end', flexGrow: 1 }}>
          <Button color="primary" fullWidth onClick={logoutAccount}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <Hidden lgUp>
            {isHidden && (
              <Tooltip arrow title="Toggle Menu">
                <IconButton color="primary" onClick={toggleSidebar}>
                  {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Hidden>
        </Box>
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
