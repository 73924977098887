import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { seasonPollAPI } from '../api/api';
import {
  addNewOption,
  addSeasonPoll,
  addSelectValue,
  changeLoading,
  changeOptionField,
  changeSeasonPoll,
  changeTypeSeasonPoll,
  deleteOption,
  deleteSelectValue,
  getSeasonPollItem,
  getSeasonPollsList,
  ISeasonPoll,
  SeasonPollContext,
  SeasonPollInitialState,
  SeasonPollReducer,
  removeSeasonPoll,
  resetSeasonPoll,
  resetSelectValue,
  TypeSeasonPoll,
} from '../reducers/seasonPollReducer';

type SeasonPollProviderProps = {
  children: ReactNode;
};

const SeasonPollProvider = (props: SeasonPollProviderProps): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(
    SeasonPollReducer,
    SeasonPollInitialState,
  );
  const navigate = useNavigate();

  const getSeasonPolls = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await seasonPollAPI.getSeasonPolls();
      dispatch(getSeasonPollsList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getSeasonPoll = useCallback(async (SeasonPollId: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await seasonPollAPI.getSeasonPoll(SeasonPollId);
      dispatch(getSeasonPollItem(result.data.data));
      // console.log(result.data.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const createSeasonPoll = useCallback(
    async (SeasonPoll: ISeasonPoll): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await seasonPollAPI.createSeasonPoll(SeasonPoll);

        const { id } = result.data.data;
        dispatch(addSeasonPoll(result.data.data));
        toast.success('SeasonPoll added successfully!');
        navigate(`/seasonPolls/${id}`);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(e?.message || 'Something went wrong!');
        }
        // toast.error(e.response.data.message || 'Something went wrong!');
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [navigate],
  );

  const updateSeasonPoll = useCallback(
    async (
      SeasonPoll: ISeasonPoll,
      id: string,
      setError: UseFormSetError<ISeasonPoll>,
    ): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await seasonPollAPI.updateSeasonPoll(SeasonPoll, id);
        dispatch(changeSeasonPoll(result.data.data));
        toast.success('SeasonPoll updated successfully!');
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const error = e.response?.data?.errors;
        if (error) {
          const err = Object.entries(error);
          err.forEach((el) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(`${el[0]}`, {
              type: 'server',
              message: `${el[1]}`,
            });
          });
        }
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [],
  );

  const deleteSeasonPoll = useCallback(async (SeasonPollId: string) => {
    dispatch(changeLoading(true));
    try {
      await seasonPollAPI.deleteSeasonPoll(SeasonPollId);
      dispatch(removeSeasonPoll(SeasonPollId));
      toast.success('SeasonPoll deleted successfully!');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const clearSeasonPoll = useCallback(() => {
    dispatch(resetSeasonPoll());
  }, []);

  const addOption = useCallback(() => {
    dispatch(addNewOption());
  }, []);

  const removeOption = useCallback(
    (optionId: string | undefined, index: number) => {
      dispatch(deleteOption(optionId, index));
    },
    [],
  );

  const updateOptionField = useCallback(
    (index: number, name: string, value: string) => {
      dispatch(changeOptionField(index, name, value));
    },
    [],
  );

  const createSelectValue = useCallback((optionId: number, value: string) => {
    dispatch(addSelectValue(optionId, value));
  }, []);

  const clearSelectValues = useCallback((optionId: number) => {
    dispatch(resetSelectValue(optionId));
  }, []);

  const removeSelectValue = useCallback((optionId: number, index: number) => {
    dispatch(deleteSelectValue(optionId, index));
  }, []);

  const changeType = useCallback(async (value: TypeSeasonPoll) => {
    dispatch(changeTypeSeasonPoll(value));
  }, []);

  const SeasonPollValue = useMemo(
    () => ({
      ...state,
      getSeasonPolls,
      createSeasonPoll,
      getSeasonPoll,
      deleteSeasonPoll,
      updateSeasonPoll,
      clearSeasonPoll,
      addOption,
      removeOption,
      updateOptionField,
      createSelectValue,
      clearSelectValues,
      removeSelectValue,
      changeType,
    }),
    [
      state,
      getSeasonPolls,
      createSeasonPoll,
      getSeasonPoll,
      deleteSeasonPoll,
      updateSeasonPoll,
      clearSeasonPoll,
      addOption,
      removeOption,
      updateOptionField,
      createSelectValue,
      clearSelectValues,
      removeSelectValue,
      changeType,
    ],
  );

  return (
    <SeasonPollContext.Provider value={SeasonPollValue}>
      {children}
    </SeasonPollContext.Provider>
  );
};

export default SeasonPollProvider;
