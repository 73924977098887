import { createContext } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { IPollItem } from './pollReducer';

export type OpponentType = {
  name: string;
  logo: string;
};

export type FinishOptionsType = {
  matchScore: {
    liverpool: number;
    opponent: number;
  };
};

export interface IMatch {
  isEnabled: boolean;
  isFinished: boolean;
  isHomeMatch: boolean;
  eventName: string;
  opponent: OpponentType;
  link: string;
  matchDate: Date | null;
  baseDeadline?: Date | null;
  lineUpDeadline?: Date | null;
  communityPollDeadline?: Date | null;
  polls: IPollItem[];
  finishOptions: FinishOptionsType;
  id?: string;
}

export type MatchStateType = {
  matches: IMatch[];
  match: IMatch | null;
  isLoading: boolean;
  opponents: OpponentType[];
};

export const MatchInitialState: MatchStateType = {
  matches: [],
  match: null,
  isLoading: false,
  opponents: [],
};

export const matchReducer = (
  state: MatchStateType,
  action: ActionsType,
): MatchStateType => {
  switch (action.type) {
    case 'match/reds-club/GET-MATCHES':
    case 'match/reds-club/GET-MATCH':
    case 'match/reds-club/RESET-MATCH':
    case 'match/reds-club/CHANGE-IS-LOADING':
    case 'match/reds-club/GET-OPPONENTS-MATCH':
      return {
        ...state,
        ...action.payload,
      };
    case 'match/reds-club/DELETE-MATCH': {
      return {
        ...state,
        matches: [...state.matches.filter((el) => el.id !== action.payload)],
      };
    }
    case 'match/reds-club/RESET-LOGO-OPPONENT':
      return {
        ...state,
        match: {
          ...state.match,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          opponent: {
            ...state.match?.opponent,
            logo: '',
          },
        },
      };
    case 'match/reds-club/DELETE-POLL':
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        match: {
          ...state.match,
          polls: [
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...state.match.polls.filter((el) => el.id !== action.payload),
          ],
        },
      };
    case 'match/reds-club/CHNGE-OPPONENT-MATCH': {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        match: {
          ...state.match,
          opponent: {
            ...state.match?.opponent,
            ...action.payload,
          },
        },
      };
    }
    case 'match/reds-club/CHANGE-FINISH-MATCH': {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        match: {
          ...state.match,
          isFinished: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const resetMatch = () =>
  ({
    type: 'match/reds-club/RESET-MATCH',
    payload: { match: null },
  } as const);

export const getMatchesList = (matches: IMatch[]) =>
  ({
    type: 'match/reds-club/GET-MATCHES',
    payload: { matches },
  } as const);

export const getMatchItem = (match: IMatch) =>
  ({
    type: 'match/reds-club/GET-MATCH',
    payload: { match },
  } as const);

export const getOpponents = (opponents: OpponentType[]) =>
  ({
    type: 'match/reds-club/GET-OPPONENTS-MATCH',
    payload: { opponents },
  } as const);

export const changeOpponent = (opponent: OpponentType) =>
  ({
    type: 'match/reds-club/CHNGE-OPPONENT-MATCH',
    payload: opponent,
  } as const);

export const removeMatch = (id: string) =>
  ({
    type: 'match/reds-club/DELETE-MATCH',
    payload: id,
  } as const);

export const changeLoading = (isLoading: boolean) =>
  ({
    type: 'match/reds-club/CHANGE-IS-LOADING',
    payload: { isLoading },
  } as const);

export const resetLogoOpponent = () =>
  ({ type: 'match/reds-club/RESET-LOGO-OPPONENT' } as const);

export const deletePoll = (id: string) =>
  ({
    type: 'match/reds-club/DELETE-POLL',
    payload: id,
  } as const);

export const changeIsFinishMatch = (isFinished: boolean) =>
  ({
    type: 'match/reds-club/CHANGE-FINISH-MATCH',
    payload: isFinished,
  } as const);

type ActionsType =
  | ReturnType<typeof resetMatch>
  | ReturnType<typeof getMatchesList>
  | ReturnType<typeof getMatchItem>
  | ReturnType<typeof removeMatch>
  | ReturnType<typeof resetLogoOpponent>
  | ReturnType<typeof deletePoll>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof getOpponents>
  | ReturnType<typeof changeOpponent>
  | ReturnType<typeof changeIsFinishMatch>;

interface MatchContextValue extends MatchStateType {
  getMatches: () => Promise<void>;
  getOpponentsMatch: () => Promise<void>;
  createMatch: (
    match: IMatch,
    setError: UseFormSetError<IMatch>,
  ) => Promise<void>;
  getMatch: (matchId: string) => Promise<void>;
  deleteMatch: (matchId: string) => Promise<void>;
  updateMatch: (
    match: IMatch,
    id: string,
    setError: UseFormSetError<IMatch>,
  ) => Promise<void>;
  clearMatch: () => void;
  clearLogo: () => void;
  removePoll: (matchId: string, pollId: string) => Promise<void>;
  addPoll: (matchId: string, pollId: string) => Promise<void>;
  changeOpponentMatch: (opponent: OpponentType) => void;
  changeFinishMatch: (isFinished: boolean) => void;
}

export const MatchContext = createContext<MatchContextValue>({
  ...MatchInitialState,
  getMatches: () => Promise.resolve(),
  getOpponentsMatch: () => Promise.resolve(),
  createMatch: () => Promise.resolve(),
  getMatch: () => Promise.resolve(),
  deleteMatch: () => Promise.resolve(),
  updateMatch: () => Promise.resolve(),
  removePoll: () => Promise.resolve(),
  addPoll: () => Promise.resolve(),
  clearMatch: () => {},
  clearLogo: () => {},
  changeOpponentMatch: () => {},
  changeFinishMatch: () => {},
});
