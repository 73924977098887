import { createContext } from 'react';

export interface ISeason {
  title: string;
  isActual: boolean;
  description: string;
  id?: string;
}

export interface IVote {
  id: string;
  match: {
    eventName: string;
    opponent: {
      name: string;
    };
  };
  poll: {
    title: string;
  };
  user: {
    _id: string;
    name: {
      nickname: string;
    };
  };
  answer: string[];
  communityAnswer: {
    footballer: string;
    stars: number;
  }[];
  accrued: boolean;
}

export type SeasonStateType = {
  Seasons: ISeason[];
  Season: ISeason | null;
  Votes: IVote[];
  isLoading: boolean;
};

export const SeasonInitialState: SeasonStateType = {
  Seasons: [],
  Votes: [],
  Season: null,
  isLoading: false,
};

export const seasonReducer = (
  state: SeasonStateType,
  action: ActionsType,
): SeasonStateType => {
  switch (action.type) {
    case 'Season/reds-club/CHANGE-IS-LOADING':
      return {
        ...state,
        ...action.payload,
      };
    case 'Season/reds-club/GET-SeasonS':
    case 'Season/reds-club/GET-ALLVOTES':
    case 'Season/reds-club/GET-Season':
    case 'Season/reds-club/RESET-Season':
    case 'Season/reds-club/UPDATE-Season':
      return {
        ...state,
        ...action.payload,
      };
    case 'Season/reds-club/DELETE-Season':
      return {
        ...state,
        Seasons: [...state.Seasons.filter((el) => el.id !== action.payload)],
      };
    case 'Season/reds-club/ADD-Season':
      return {
        ...state,
        Seasons: [...state.Seasons, action.payload],
      };
    default:
      return state;
  }
};

export const resetSeason = () =>
  ({
    type: 'Season/reds-club/RESET-Season',
    payload: { Season: null },
  } as const);

export const getSeasonsList = (Seasons: ISeason[]) =>
  ({
    type: 'Season/reds-club/GET-SeasonS',
    payload: { Seasons },
  } as const);

export const getAllVotesList = (Votes: IVote[]) =>
  ({
    type: 'Season/reds-club/GET-ALLVOTES',
    payload: { Votes },
  } as const);

export const getSeasonItem = (Season: ISeason) =>
  ({
    type: 'Season/reds-club/GET-Season',
    payload: { Season },
  } as const);

export const updateSeasonItem = (Season: ISeason) =>
  ({
    type: 'Season/reds-club/UPDATE-Season',
    payload: { Season },
  } as const);

export const addSeasons = (Season: ISeason) =>
  ({
    type: 'Season/reds-club/ADD-Season',
    payload: Season,
  } as const);

export const deleteSeasonItem = (SeasonId: string) =>
  ({
    type: 'Season/reds-club/DELETE-Season',
    payload: SeasonId,
  } as const);

export const changeLoading = (isLoading: boolean) =>
  ({
    type: 'Season/reds-club/CHANGE-IS-LOADING',
    payload: { isLoading },
  } as const);

type ActionsType =
  | ReturnType<typeof getSeasonsList>
  | ReturnType<typeof deleteSeasonItem>
  | ReturnType<typeof addSeasons>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof resetSeason>
  | ReturnType<typeof updateSeasonItem>
  | ReturnType<typeof getSeasonItem>
  | ReturnType<typeof getAllVotesList>;

interface SeasonContextValue extends SeasonStateType {
  getSeasons: () => Promise<void>;
  getAllVotes: () => Promise<void>;
  createSeason: (Season: ISeason) => Promise<void>;
  getSeason: (SeasonId: string) => Promise<void>;
  deleteSeason: (SeasonId: string) => Promise<void>;
  updateSeason: (Season: ISeason, id: string) => Promise<void>;
  clearSeason: () => void;
}

export const SeasonContext = createContext<SeasonContextValue>({
  ...SeasonInitialState,
  getSeasons: () => Promise.resolve(),
  getAllVotes: () => Promise.resolve(),
  createSeason: () => Promise.resolve(),
  getSeason: () => Promise.resolve(),
  deleteSeason: () => Promise.resolve(),
  updateSeason: () => Promise.resolve(),
  clearSeason: () => {},
});
