import React, { useCallback, useState } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { themeCreator } from './base';

export const ThemeContext = React.createContext(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (themeName: string): void => {},
);

const ThemeProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, setThemeNameInt] = useState(curThemeName);
  const theme = themeCreator(themeName);

  const setThemeName = useCallback(
    (saveTheme: string): void => {
      localStorage.setItem('appTheme', saveTheme);
      setThemeNameInt(themeName);
    },
    [themeName],
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;
