/* eslint-disable no-underscore-dangle */
import { createContext } from 'react';
import { UseFormSetError } from 'react-hook-form';

export type TypeField = 'string' | 'select' | 'checked';
export type TypePoll = 'base' | 'lineUp' | 'community' | 'top1Player';

export type OptionType = {
  title: string;
  typeField: TypeField;
  description: string;
  selectValues?: string[];
  _id?: string;
};

export interface IPollItem {
  poll: IPoll;
  rightAnswers: string[];
  isRightAnswersAdded: boolean;
}

export interface IPoll {
  title: string;
  isEnabled: boolean;
  description: string;
  amountPoints: number;
  options: OptionType[];
  typePoll: TypePoll;
  id?: string;
  _id?: string;
}

export type PollStateType = {
  polls: IPoll[];
  poll: IPoll | null;
  isLoading: boolean;
};

export const PollInitialState: PollStateType = {
  polls: [],
  poll: null,
  isLoading: false,
};

export const pollReducer = (
  state: PollStateType,
  action: ActionsType,
): PollStateType => {
  switch (action.type) {
    case 'poll/reds-club/CHANGE-IS-LOADING':
      return {
        ...state,
        ...action.payload,
      };
    case 'poll/reds-club/GET-POLLS':
    case 'poll/reds-club/GET-POLL':
    case 'poll/reds-club/RESET-POLL':
    case 'poll/reds-club/UPDATE-POLL':
      return {
        ...state,
        ...action.payload,
      };
    case 'poll/reds-club/DELETE-POLL':
      return {
        ...state,
        polls: [...state.polls.filter((el) => el.id !== action.payload)],
      };
    case 'poll/reds-club/ADD-POLL':
      return {
        ...state,
        polls: [...state.polls, action.payload],
      };
    case 'poll/reds-club/ADD-OPTION': {
      const options = state.poll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        poll: {
          ...state.poll,
          options: [
            { title: '', description: '', typeField: 'string' },
            ...options,
          ],
        },
      };
    }
    case 'poll/reds-club/DELETE-OPTION': {
      const { optionId, index } = action.payload;
      const options = state.poll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        poll: {
          ...state.poll,
          options: options.filter((el, i) => {
            if (optionId) {
              return el._id !== optionId;
            }
            return i !== index;
          }),
        },
      };
    }
    case 'poll/reds-club/CHANGE-OPTION-FIELD': {
      const { name, value, index } = action.payload;
      const nameField = name.substring(10, name.length);
      const options = state.poll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        poll: {
          ...state.poll,
          options: options.map((el, i) => {
            if (i === index) {
              return { ...el, [`${nameField}`]: value };
            }
            return el;
          }),
        },
      };
    }
    case 'poll/reds-club/ADD-OPTION-SELECT-VALUE': {
      const { index, value } = action.payload;
      const options = state.poll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        poll: {
          ...state.poll,
          options: options.map((el, i) => {
            const selectValues = el.selectValues || [];
            if (i === index) {
              return { ...el, selectValues: [value, ...selectValues] };
            }
            return el;
          }),
        },
      };
    }
    case 'poll/reds-club/DELETE-OPTION-SELECT-VALUE': {
      const { index, optionId } = action.payload;
      const options = state.poll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        poll: {
          ...state.poll,
          options: options.map((el, i) => {
            const selectValues =
              el.selectValues?.filter((value, j) => j !== index) || [];
            if (i === optionId) {
              return { ...el, selectValues: [...selectValues] };
            }
            return el;
          }),
        },
      };
    }
    case 'poll/reds-club/RESET-OPTION-SELECT-VALUES': {
      const options = state.poll?.options || [];
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        poll: {
          ...state.poll,
          options: options.map((el, i) => {
            if (i === action.payload) {
              return { ...el, selectValues: [] };
            }
            return el;
          }),
        },
      };
    }
    case 'poll/reds-club/CHANGE-POLL-TYPE': {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        poll: {
          ...state.poll,
          typePoll: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export const resetPoll = () =>
  ({
    type: 'poll/reds-club/RESET-POLL',
    payload: { poll: null },
  } as const);

export const getPollsList = (polls: IPoll[]) =>
  ({
    type: 'poll/reds-club/GET-POLLS',
    payload: { polls },
  } as const);

export const getPollItem = (poll: IPoll) =>
  ({
    type: 'poll/reds-club/GET-POLL',
    payload: { poll },
  } as const);

export const changePoll = (poll: IPoll) =>
  ({
    type: 'poll/reds-club/UPDATE-POLL',
    payload: { poll },
  } as const);

export const addPoll = (poll: IPoll) =>
  ({
    type: 'poll/reds-club/ADD-POLL',
    payload: poll,
  } as const);

export const removePoll = (pollId: string) =>
  ({
    type: 'poll/reds-club/DELETE-POLL',
    payload: pollId,
  } as const);

export const changeLoading = (isLoading: boolean) =>
  ({
    type: 'poll/reds-club/CHANGE-IS-LOADING',
    payload: { isLoading },
  } as const);

export const addNewOption = () =>
  ({ type: 'poll/reds-club/ADD-OPTION' } as const);

export const deleteOption = (optionId: string | undefined, index: number) =>
  ({
    type: 'poll/reds-club/DELETE-OPTION',
    payload: { optionId, index },
  } as const);

export const changeOptionField = (index: number, name: string, value: string) =>
  ({
    type: 'poll/reds-club/CHANGE-OPTION-FIELD',
    payload: { name, value, index },
  } as const);

export const changeTypePoll = (value: TypePoll) =>
  ({
    type: 'poll/reds-club/CHANGE-POLL-TYPE',
    payload: value,
  } as const);

export const addSelectValue = (index: number, value: string) =>
  ({
    type: 'poll/reds-club/ADD-OPTION-SELECT-VALUE',
    payload: { index, value },
  } as const);

export const deleteSelectValue = (optionId: number, index: number) =>
  ({
    type: 'poll/reds-club/DELETE-OPTION-SELECT-VALUE',
    payload: { optionId, index },
  } as const);

export const resetSelectValue = (index: number) =>
  ({
    type: 'poll/reds-club/RESET-OPTION-SELECT-VALUES',
    payload: index,
  } as const);

type ActionsType =
  | ReturnType<typeof getPollsList>
  | ReturnType<typeof removePoll>
  | ReturnType<typeof addPoll>
  | ReturnType<typeof changeLoading>
  | ReturnType<typeof resetPoll>
  | ReturnType<typeof changePoll>
  | ReturnType<typeof getPollItem>
  | ReturnType<typeof addNewOption>
  | ReturnType<typeof deleteOption>
  | ReturnType<typeof changeOptionField>
  | ReturnType<typeof addSelectValue>
  | ReturnType<typeof resetSelectValue>
  | ReturnType<typeof changeTypePoll>
  | ReturnType<typeof deleteSelectValue>;

interface PollContextValue extends PollStateType {
  getPolls: () => Promise<void>;
  createPoll: (poll: IPoll) => Promise<void>;
  getPoll: (pollId: string) => Promise<void>;
  filterPolls: (typeName: string) => Promise<void>;
  deletePoll: (pollId: string) => Promise<void>;
  updatePoll: (
    poll: IPoll,
    id: string,
    setError: UseFormSetError<IPoll>,
  ) => Promise<void>;
  clearPoll: () => void;
  addOption: () => void;
  removeOption: (optionId: string | undefined, index: number) => void;
  updateOptionField: (index: number, name: string, value: string) => void;
  createSelectValue: (optionId: number, value: string) => void;
  clearSelectValues: (optionId: number) => void;
  removeSelectValue: (optionId: number, index: number) => void;
  changeType: (value: TypePoll) => void;
  addRightAnswers: (
    matchId: string,
    rightAnswers: [
      {
        pollId: string;
        answers: string[];
      },
    ],
  ) => void;
}

export const PollContext = createContext<PollContextValue>({
  ...PollInitialState,
  getPolls: () => Promise.resolve(),
  createPoll: () => Promise.resolve(),
  getPoll: () => Promise.resolve(),
  filterPolls: () => Promise.resolve(),
  deletePoll: () => Promise.resolve(),
  updatePoll: () => Promise.resolve(),
  addRightAnswers: () => Promise.resolve(),
  clearPoll: () => {},
  addOption: () => {},
  removeOption: () => {},
  updateOptionField: () => {},
  createSelectValue: () => {},
  clearSelectValues: () => {},
  removeSelectValue: () => {},
  changeType: () => {},
});
