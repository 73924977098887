import { Box, IconButton, Tooltip } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

function HeaderButtons() {
  return (
    <Box sx={{ mr: 1 }}>
      <Link to="/">
        <Tooltip arrow title="Back home">
          <IconButton color="primary">
            <HomeIcon />
          </IconButton>
        </Tooltip>
      </Link>
    </Box>
  );
}

export default HeaderButtons;
