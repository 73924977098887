import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UseFormSetError } from 'react-hook-form';
import { pollAPI } from '../api/api';
import {
  addNewOption,
  addPoll,
  addSelectValue,
  changeLoading,
  changeOptionField,
  changePoll,
  changeTypePoll,
  deleteOption,
  deleteSelectValue,
  getPollItem,
  getPollsList,
  IPoll,
  PollContext,
  PollInitialState,
  pollReducer,
  removePoll,
  resetPoll,
  resetSelectValue,
  TypePoll,
} from '../reducers/pollReducer';

type PollProviderProps = {
  children: ReactNode;
};

const PollProvider = (props: PollProviderProps): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(pollReducer, PollInitialState);
  const navigate = useNavigate();

  const getPolls = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await pollAPI.getPolls();
      dispatch(getPollsList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getPoll = useCallback(async (pollId: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await pollAPI.getPoll(pollId);
      dispatch(getPollItem(result.data.data));
      // console.log(result.data.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const filterPolls = useCallback(async (typeName: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await pollAPI.filterPolls(typeName);
      dispatch(getPollsList(result.data.data));
      // console.log(result.data.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const createPoll = useCallback(
    async (poll: IPoll): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await pollAPI.createPoll(poll);
        const { id } = result.data.data;
        dispatch(addPoll(result.data.data));
        toast.success('Poll added successfully!');
        navigate(`/polls/${id}`);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(e?.message || 'Something went wrong!');
        }
        // toast.error(e.response.data.message || 'Something went wrong!');
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [navigate],
  );

  const updatePoll = useCallback(
    async (
      poll: IPoll,
      id: string,
      setError: UseFormSetError<IPoll>,
    ): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await pollAPI.updatePoll(poll, id);
        dispatch(changePoll(result.data.data));
        toast.success('Poll updated successfully!');
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const error = e.response?.data?.errors;
        if (error) {
          const err = Object.entries(error);
          err.forEach((el) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(`${el[0]}`, {
              type: 'server',
              message: `${el[1]}`,
            });
          });
        }
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [],
  );

  const addRightAnswers = useCallback(
    async (
      matchId: string,
      rightAnswers: [
        {
          pollId: string;
          answers: string[];
        },
      ],
    ) => {
      dispatch(changeLoading(true));
      try {
        const result = await pollAPI.addRightAnswers(matchId, rightAnswers);
        dispatch(changePoll(result.data.data));
        toast.success('Right answers added successfully!');
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const error = e.response?.data;
        toast.error(error?.message || 'Something went wrong!');
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [],
  );

  const deletePoll = useCallback(async (pollId: string) => {
    dispatch(changeLoading(true));
    try {
      await pollAPI.deletePoll(pollId);
      dispatch(removePoll(pollId));
      toast.success('Poll deleted successfully!');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const clearPoll = useCallback(() => {
    dispatch(resetPoll());
  }, []);

  const addOption = useCallback(() => {
    dispatch(addNewOption());
  }, []);

  const removeOption = useCallback(
    (optionId: string | undefined, index: number) => {
      dispatch(deleteOption(optionId, index));
    },
    [],
  );

  const updateOptionField = useCallback(
    (index: number, name: string, value: string) => {
      dispatch(changeOptionField(index, name, value));
    },
    [],
  );

  const createSelectValue = useCallback((optionId: number, value: string) => {
    dispatch(addSelectValue(optionId, value));
  }, []);

  const clearSelectValues = useCallback((optionId: number) => {
    dispatch(resetSelectValue(optionId));
  }, []);

  const removeSelectValue = useCallback((optionId: number, index: number) => {
    dispatch(deleteSelectValue(optionId, index));
  }, []);

  const changeType = useCallback(async (value: TypePoll) => {
    dispatch(changeTypePoll(value));
  }, []);

  const PollValue = useMemo(
    () => ({
      ...state,
      getPolls,
      createPoll,
      getPoll,
      filterPolls,
      deletePoll,
      updatePoll,
      clearPoll,
      addOption,
      removeOption,
      updateOptionField,
      createSelectValue,
      clearSelectValues,
      removeSelectValue,
      changeType,
      addRightAnswers,
    }),
    [
      state,
      getPolls,
      createPoll,
      getPoll,
      filterPolls,
      deletePoll,
      updatePoll,
      clearPoll,
      addOption,
      removeOption,
      updateOptionField,
      createSelectValue,
      clearSelectValues,
      removeSelectValue,
      changeType,
      addRightAnswers,
    ],
  );

  return (
    <PollContext.Provider value={PollValue}>{children}</PollContext.Provider>
  );
};

export default PollProvider;
