import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  addSeasons,
  changeLoading,
  deleteSeasonItem,
  SeasonContext,
  SeasonInitialState,
  getSeasonsList,
  getSeasonItem,
  ISeason,
  seasonReducer,
  resetSeason,
  updateSeasonItem,
  getAllVotesList,
} from '../reducers/seasonReducer';
import { seasonAPI } from '../api/api';

type ProviderProps = {
  children: ReactNode;
};

const SeasonProvider = (props: ProviderProps): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(seasonReducer, SeasonInitialState);
  const navigate = useNavigate();

  const getSeasons = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await seasonAPI.getSeasons();
      dispatch(getSeasonsList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getAllVotes = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await seasonAPI.getVotes();
      dispatch(getAllVotesList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getSeason = useCallback(async (SeasonId: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await seasonAPI.getSeason(SeasonId);
      dispatch(getSeasonItem(result.data.data));
      // console.log(result.data.data);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const createSeason = useCallback(
    async (season: ISeason): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await seasonAPI.createSeason(season);
        const { id } = result.data.data;
        dispatch(addSeasons(result.data.data));
        toast.success('Season added successfully!');
        navigate(`/Seasons/${id}`);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(e?.message || 'Something went wrong!');
        }
        // toast.error(e.response.data.message || 'Something went wrong!');
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [navigate],
  );

  const updateSeason = useCallback(
    async (Season: ISeason, id: string): Promise<void> => {
      dispatch(changeLoading(true));
      try {
        const result = await seasonAPI.updateSeason(Season, id);
        dispatch(updateSeasonItem(result.data.data));
        toast.success('Season updated successfully!');
      } catch (e) {
        if (axios.isAxiosError(e)) {
          toast.error(e?.message || 'Something went wrong!');
        }
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [],
  );

  const deleteSeason = useCallback(async (SeasonId: string) => {
    dispatch(changeLoading(true));
    try {
      await seasonAPI.deleteSeason(SeasonId);
      dispatch(deleteSeasonItem(SeasonId));
      toast.success('Season deleted successfully!');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const clearSeason = useCallback(() => {
    dispatch(resetSeason());
  }, []);

  const ProviderValue = useMemo(
    () => ({
      ...state,
      getSeasons,
      getAllVotes,
      createSeason,
      getSeason,
      clearSeason,
      updateSeason,
      deleteSeason,
    }),
    [
      state,
      getSeasons,
      getAllVotes,
      createSeason,
      getSeason,
      clearSeason,
      updateSeason,
      deleteSeason,
    ],
  );

  return (
    <SeasonContext.Provider value={ProviderValue}>
      {children}
    </SeasonContext.Provider>
  );
};

export default SeasonProvider;
