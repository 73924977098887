import { ReactNode, useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UseFormSetError } from 'react-hook-form';
import {
  changeIsFinishMatch,
  changeLoading,
  changeOpponent,
  getMatchesList,
  getMatchItem,
  getOpponents,
  IMatch,
  MatchContext,
  MatchInitialState,
  matchReducer,
  OpponentType,
  removeMatch,
  resetLogoOpponent,
  resetMatch,
} from '../reducers/matchReducer';
import { matchAPI } from '../api/api';

type ProviderProps = {
  children: ReactNode;
};

const MatchProvider = (props: ProviderProps): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer(matchReducer, MatchInitialState);
  const navigate = useNavigate();

  const getMatches = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.getMatches();
      dispatch(getMatchesList(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getMatch = useCallback(async (id: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.getMatch(id);
      dispatch(getMatchItem(result.data.data));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const deleteMatch = useCallback(async (id: string) => {
    dispatch(changeLoading(true));
    try {
      await matchAPI.deleteMatch(id);
      dispatch(removeMatch(id));
      toast.success('Match deleted successfully!');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const createMatch = useCallback(
    async (match: IMatch, setError: UseFormSetError<IMatch>) => {
      dispatch(changeLoading(true));
      try {
        const result = await matchAPI.createMatch(match);
        const { id } = result.data.data;
        toast.success('Match added successfully!');
        navigate(`matches/${id}`);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const error = e.response?.data?.errors;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const message = e.response?.data?.message;
        if (message) {
          toast.error(message);
        }
        if (error?.['opponent.logo']) {
          const logoError = error?.['opponent.logo'];
          toast.error(logoError[0] || 'Something went wrong!');
        } else if (error) {
          const err = Object.entries(error);
          err.forEach((el) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(`${el[0]}`, {
              type: 'server',
              message: `${el[1]}`,
            });
          });
        }
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [navigate],
  );

  const updateMatch = useCallback(
    async (match: IMatch, id: string, setError: UseFormSetError<IMatch>) => {
      dispatch(changeLoading(true));
      try {
        const result = await matchAPI.updateMatch(match, id);
        dispatch(getMatchItem(result.data.data));
        toast.success('Match update successfully!');
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const error = e.response?.data?.errors;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const message = e.response?.data?.message;
        if (message) {
          toast.error(message || 'Something went wrong!');
        }
        if (error?.['opponent.logo']) {
          const logoError = error?.['opponent.logo'];
          toast.error(logoError[0] || 'Something went wrong!');
        } else if (error) {
          const err = Object.entries(error);
          err.forEach((el) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setError(`${el[0]}`, {
              type: 'server',
              message: `${el[1]}`,
            });
          });
        }
      } finally {
        dispatch(changeLoading(false));
      }
    },
    [],
  );

  const removePoll = useCallback(async (matchId: string, pollId: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.deletePoll(matchId, pollId);
      dispatch(getMatchItem(result.data.data));
      toast.success('Poll deleted successfully!');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const addPoll = useCallback(async (matchId: string, pollId: string) => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.addPoll(matchId, pollId);
      dispatch(getMatchItem(result.data.data));
      toast.success('Poll deleted successfully!');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const getOpponentsMatch = useCallback(async () => {
    dispatch(changeLoading(true));
    try {
      const result = await matchAPI.getOpponents();
      const opponents = Object.entries(result.data.data).map((el) => ({
        name: el[0] as string,
        logo: el[1] as string,
      }));
      dispatch(getOpponents(opponents));
    } catch (e) {
      if (axios.isAxiosError(e)) {
        toast.error(e?.message || 'Something went wrong!');
      }
    } finally {
      dispatch(changeLoading(false));
    }
  }, []);

  const clearMatch = useCallback(async () => {
    dispatch(resetMatch());
  }, []);

  const clearLogo = useCallback(async () => {
    dispatch(resetLogoOpponent());
  }, []);

  const changeOpponentMatch = useCallback(async (opponent: OpponentType) => {
    dispatch(changeOpponent(opponent));
  }, []);

  const changeFinishMatch = useCallback(async (isFinished: boolean) => {
    dispatch(changeIsFinishMatch(isFinished));
  }, []);

  const Providervalue = useMemo(
    () => ({
      ...state,
      getMatches,
      getMatch,
      deleteMatch,
      createMatch,
      updateMatch,
      clearMatch,
      clearLogo,
      removePoll,
      addPoll,
      getOpponentsMatch,
      changeOpponentMatch,
      changeFinishMatch,
    }),
    [
      state,
      getMatches,
      getMatch,
      deleteMatch,
      createMatch,
      updateMatch,
      clearMatch,
      clearLogo,
      removePoll,
      addPoll,
      getOpponentsMatch,
      changeOpponentMatch,
      changeFinishMatch,
    ],
  );

  return (
    <MatchContext.Provider value={Providervalue}>
      {children}
    </MatchContext.Provider>
  );
};

export default MatchProvider;
