import {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
} from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  AuthContext,
  AuthInitialState,
  authReducer,
  changeAuthData,
  clearLogin,
  initialize,
  setLogin,
  logout,
  LoginType,
  loading,
  // setUser,
} from '../reducers/authReducer';
import { authAPI } from '../api/api';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(authReducer, AuthInitialState);
  const navigate = useNavigate();

  const initializeAccount = async (): Promise<void> => {
    try {
      const accessToken = await localStorage.getItem('redsclubToken');
      const user = await localStorage.getItem('userProfile');
      if (accessToken && user) {
        dispatch(initialize(true, JSON.parse(user)));
      } else {
        await dispatch(initialize(false, null));
        navigate('/authentication');
      }
    } catch (err) {
      dispatch(initialize(false, null));
    }
  };

  const loginAdmin = useCallback(
    async (data: LoginType) => {
      dispatch(loading(true));
      try {
        const result = await authAPI.login(data);
        const { token, user } = result;
        dispatch(setLogin(user));
        localStorage.setItem('redsclubToken', token.token);
        await localStorage.setItem('userProfile', JSON.stringify(user));
        navigate('/');
        toast.success('login success!');
      } catch (e) {
        dispatch(initialize(false, null));
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const error = e?.response?.data?.message;
        toast.error(error || 'Something went wrong. Check email and password!');
      } finally {
        dispatch(loading(false));
      }
    },
    [navigate],
  );

  const logoutAccount = useCallback(async () => {
    await localStorage.removeItem('userProfile');
    await dispatch(logout());
    await dispatch(setLogin(null));
    dispatch(clearLogin());
    navigate('/authentication');
  }, [navigate]);

  const changeAuth = (name: string, value: string) => {
    dispatch(changeAuthData(name, value));
  };

  const contextValue = useMemo(
    () => ({
      ...state,
      logoutAccount,
      changeAuth,
      loginAdmin,
    }),
    [state, logoutAccount, loginAdmin],
  );

  useEffect(() => {
    initializeAccount();
    // dispatch(setUser(JSON.parse(localStorage.getItem('userProfile'))) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isAuthenticated]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
